import axios from "axios";

export const SubmitHubSpotForm = async (
  email: string,
  firstname: string,
  lastname: string,
  company: string
): Promise<{ status: number; data: any }> => {
  const portalId = "47491877";
  const formGuid = "4b2e8b69-293d-487b-b49e-1877f9587a31";
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.post(
    `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
    {
      portalId,
      formGuid,
      fields: [
        {
          name: "firstname",
          value: firstname,
        },
        {
          name: "lastname",
          value: lastname,
        },
        {
          name: "email",
          value: email,
        },
        {
          name: "0-2/company_name",
          value: company,
        },
      ],
    },
    config
  );

  return {
    status: response.status,
    data: response.data,
  };
};
