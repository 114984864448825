import * as yup from "yup";

export const advisoryFormSchema = yup.object({
  firstName: yup.string().required("* Required"),
  lastName: yup.string().required("* Required"),
  emailAddress: yup
    .string()
    .email("Invalid email address")
    .required("* Required"),
  companyName: yup.string().required("* Required"),
});
